import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"
import TopHeader from "../components/top-header";

import { Row, Container, Col } from 'react-bootstrap/'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FaBusinessTime, FaCheck, FaFile, FaFileCode, FaNetworkWired, FaUsersCog, FaBriefcase, FaFileSignature } from "react-icons/fa";

const NasilCal = ({ intl }) => {
  return <Layout >
    <TopHeader />
    <SEO title={intl.formatMessage({ id: "page_hww_title" })} />
    <Container>
      <h1 style={{ fontWeight: '200', paddingTop: '30px' }}><FormattedMessage id="page_hww_title" /></h1>
      <h2 style={{ fontWeight: '200', fontSize: '20px' }}><FormattedMessage id="hww_title_h2" /></h2>
      <p style={{ fontWeight: '200', paddingTop: '30px' }}><FormattedMessage id="hww_desc_p" /></p>
      <Row className='slider-section'  >
        <Col sm className='' >
          <VerticalTimeline>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              iconStyle={{ background: '#36526a', color: '#fff' }}
              icon={<FaBusinessTime />}
            >
              <h3 className="vertical-timeline-element-title"><FormattedMessage id="hww_tl_box_1_title" /></h3>
              <li><FormattedMessage id="hww_tl_box_1_li_1" /></li>
              <li><FormattedMessage id="hww_tl_box_1_li_2" /></li>
              <li><FormattedMessage id="hww_tl_box_1_li_3" /></li>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              iconStyle={{ background: '#36526a', color: '#fff' }}
              icon={<FaBriefcase />}
            >
              <h3 className="vertical-timeline-element-title"><FormattedMessage id="hww_tl_box_2_title" /></h3>
              <li><FormattedMessage id="hww_tl_box_2_li_1" /></li>
              <li><FormattedMessage id="hww_tl_box_2_li_2" /></li>
              <li><FormattedMessage id="hww_tl_box_2_li_3" /></li>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              iconStyle={{ background: '#36526a', color: '#fff' }}
              icon={<FaFileSignature />}
            >
              <h3 className="vertical-timeline-element-title"><FormattedMessage id="hww_tl_box_3_title" /></h3>
              <li><FormattedMessage id="hww_tl_box_3_li_1" /></li>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              iconStyle={{ background: '#277693', color: '#fff' }}
              icon={<FaFileCode />}
            >
              <h3 className="vertical-timeline-element-title"><FormattedMessage id="hww_tl_box_4_title" /></h3>
              <li><FormattedMessage id="hww_tl_box_4_li_1" /></li>
              <li><FormattedMessage id="hww_tl_box_4_li_2" /></li>
              <li><FormattedMessage id="hww_tl_box_4_li_3" /></li>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              iconStyle={{ background: '#277693', color: '#fff' }}
              icon={<FaUsersCog />}
            >
              <h3 className="vertical-timeline-element-title"><FormattedMessage id="hww_tl_box_5_title" /></h3>
              <li><FormattedMessage id="hww_tl_box_5_li_1" /></li>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--education"
              iconStyle={{ background: '#277693', color: '#fff' }}
              icon={<FaBusinessTime />} >
              <h3 className="vertical-timeline-element-title"><FormattedMessage id="hww_tl_box_6_title" /></h3>
              <li><FormattedMessage id="hww_tl_box_6_li_1" /></li>
              <li><FormattedMessage id="hww_tl_box_6_li_2" /></li>
              <li><FormattedMessage id="hww_tl_box_6_li_3" /></li>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--education"
              iconStyle={{ background: '#277693', color: '#fff' }}
              icon={<FaFileCode />} >
              <h3 className="vertical-timeline-element-title"><FormattedMessage id="hww_tl_box_7_title" /></h3>
              <li><FormattedMessage id="hww_tl_box_7_li_1" /></li>
              <li><FormattedMessage id="hww_tl_box_7_li_2" /></li>
              <li><FormattedMessage id="hww_tl_box_7_li_3" /></li>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--education"
              iconStyle={{ background: '#277693', color: '#fff' }}
              icon={<FaFile />}
            >
              <h3 className="vertical-timeline-element-title"><FormattedMessage id="hww_tl_box_8_title" /></h3>
              <li><FormattedMessage id="hww_tl_box_8_li_1" /></li>
              <li><FormattedMessage id="hww_tl_box_8_li_2" /></li>
              <li><FormattedMessage id="hww_tl_box_8_li_3" /></li>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--education"
              iconStyle={{ background: '#1d90af', color: '#fff' }}
              icon={<FaNetworkWired />}
            >
              <h3 className="vertical-timeline-element-title"><FormattedMessage id="hww_tl_box_9_title" /></h3>
              <li><FormattedMessage id="hww_tl_box_9_li_1" /></li>
              <li><FormattedMessage id="hww_tl_box_9_li_2" /></li>
              <li><FormattedMessage id="hww_tl_box_9_li_3" /></li>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--education"
              iconStyle={{ background: '#4caf50', color: '#fff' }}
              icon={<FaCheck />}
            >
              <h3 className="vertical-timeline-element-title"><FormattedMessage id="hww_tl_box_10_title" /></h3>
              <li><FormattedMessage id="hww_tl_box_10_li_1" /></li>
            </VerticalTimelineElement>
          </VerticalTimeline>
        </Col>
      </Row>
    </Container>
  </Layout >
}

export default injectIntl(NasilCal)
